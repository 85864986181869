/* eslint-disable import/no-cycle */
import { combineEpics, ofType } from 'redux-observable';
import {
  map, catchError, filter, mergeMap, takeUntil, delay,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import Rcookie from 'react-cookies';
import queryString from 'query-string'; // Importing default export
import apiCall from '../common/utils';
import { uploadVoiceRecordingEpic } from '../components/VoiceRecording/logic';

export const GENERIC_ACTION = 'GENERIC_ACTION';

export const genericActionCreator = (actionType, payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    ...meta,
    actionType, // initial action of api call
    url: meta.url, // url for api call
    method: meta.method, // api call method
    auth: meta.auth, // authorisation in api call
    key: meta.key, // if you are dealing with nested reducer
    customHeaders: { ...meta.customHeaders }, // object of custom headers,
    log: meta.log, // boolean for console log of action, debug purpose

    // reducer params
    reducer: meta.reducer || [], // list of reducers which are being affected by this action
    onInitiation: meta.onInitiation, /**
      initial callback function,  arguments :(current state of reducer, action object, reducer key)
      prevParams, loading, error, flag already provided
      */
    onSuccess: meta.onSuccess, // success callback function
    onFailure: meta.onFailure, // failure callback function
    disableCaching: meta.disableCaching, // boolean for disabling caching of api data, default false
  },
  dummyResponse: meta.dummyResponse, // send this if you want dummy data in epic instead of api call
  showNotification: meta.showNotification, // boolean for show notification on success
  hideNotification: meta.hideNotification, // boolean for hide notification on error
});

const genericEpic = (actions$) => actions$.pipe(
  ofType(GENERIC_ACTION),
  mergeMap((action) => of({
    type: `${action.meta.actionType}_CANCEL${action.meta.key ? `_${action.meta.key}` : ''}`,
    payload: action.payload,
    meta: action.meta,
    origin: 'generic',
  }, {
    type: action.meta.actionType,
    payload: action.payload,
    meta: action.meta,
    origin: 'generic',
    showNotification: action.showNotification,
    hideNotification: action.hideNotification,
    dummyResponse: action.dummyResponse,
    retryAction: action,
  })),
);

const apiCallEpic = (actions$) => actions$.pipe(
  filter((action) => action.origin === 'generic' && action.type.indexOf('_CANCEL') === -1),
  mergeMap((action) => {
    if (action.dummyResponse) {
      return of({
        type: `${action.meta.actionType}_SUCCESS`,
        payload: action.dummyResponse,
        meta: action.meta,
      }).pipe(
        delay(700),
      );
    }
    return ajax(apiCall(action.meta.url, action.meta.method, action.payload, action.meta.auth, action.meta.customHeaders))
      .pipe(
        map((res) => ({
          type: `${action.meta.actionType}_SUCCESS`,
          payload: res,
          meta: action.meta,
          showNotification: action.showNotification,
        })),
        takeUntil(actions$.pipe(ofType(`${action.meta.actionType}_CANCEL${action.meta.key ? `_${action.meta.key}` : ''}`))),
        catchError((err) => of({
          type: `${action.meta.actionType}_FAILURE`,
          payload: err,
          meta: action.meta,
          hideNotification: action.hideNotification,
        })),
      );
  }),
);

export function apiCallwithoutCT(url, method, body = {}) {
  let obj = {};
  if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
    obj = {
      method,
      url,
      body,
    };
  } else {
    obj = {
      method,
      url: `${url}${body && Object.keys(body).length ? `?${queryString.stringify(body)}` : ''}`,
    };
  }

  return ({
    ...obj,
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: (Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : ''),
      // 'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
      // Authorization: getCookie('accessToken'),
    },
  });
}

export default combineEpics(
  genericEpic,
  apiCallEpic,
  uploadVoiceRecordingEpic,
);
