/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import {
  map,
  catchError,
  mergeMap,
} from 'rxjs/operators';
import queryString from 'query-string';
import { apiCallwithoutCT, genericActionCreator } from '../../app/epics';
import { handleLogout } from '../../common/utils';
// import { stringify } from 'query-string';

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  flag: false,
};

const UPLOAD_VOICE_RECORDING = 'UPLOAD_VOICE_RECORDING';
const UPLOAD_VOICE_RECORDING_SUCCESS = 'UPLOAD_VOICE_RECORDING_SUCCESS';
const UPLOAD_VOICE_RECORDING_FAILURE = 'UPLOAD_VOICE_RECORDING_FAILURE';
const UPLOAD_VOICE_RECORDING_RESET = 'UPLOAD_VOICE_RECORDING_RESET';

const GET_VOICE_RECORDING = 'GET_VOICE_RECORDING';
const GET_VOICE_RECORDING_SUCCESS = 'GET_VOICE_RECORDING_SUCCESS';
const GET_VOICE_RECORDING_FAILURE = 'GET_VOICE_RECORDING_FAILURE';
const GET_VOICE_RECORDING_RESET = 'GET_VOICE_RECORDING_RESET';

export const uploadVoiceRecordingAction = (payload) => ({
  type: UPLOAD_VOICE_RECORDING,
  payload,

});
export const uploadVoiceRecordingResetAction = () => ({
  type: UPLOAD_VOICE_RECORDING_RESET,
});

export function uploadVoiceRecordingReducer(state, action = null) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case UPLOAD_VOICE_RECORDING_RESET: {
      return INITIAL_STATE;
    }
    case UPLOAD_VOICE_RECORDING: {
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
        flag: false,
      };
    }
    case UPLOAD_VOICE_RECORDING_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true,
      };
    }
    case UPLOAD_VOICE_RECORDING_FAILURE: {
      return {
        ...state,
        data: null,
        loading: false,
        error: true,
        flag: false,
      };
    }
    default:
      return state;
  }
}

export const uploadVoiceRecordingEpic = (actions$) => actions$.pipe(
  ofType(UPLOAD_VOICE_RECORDING),
  mergeMap((action) => ajax(
    // console.log(action),
    apiCallwithoutCT(
      `${process.env.apiUrl}v0/save_recording`,
      'POST',
      action.payload.body,
    ),
  ).pipe(
    map((res) => ({
      type: UPLOAD_VOICE_RECORDING_SUCCESS,
      payload: res,
    })),
    catchError((err) => of({
      type: UPLOAD_VOICE_RECORDING_FAILURE,
      payload: err,
    })),
  )),
);

export const getRecordingListAction = (payload) => genericActionCreator(
  GET_VOICE_RECORDING,
  payload.params,
  {
    url: `${process.env.apiUrl}v0/get_recording_list`,
    method: 'GET',
    auth: true,
    // headers: {
    //   // Add the Authorization header with the bearer token
    //   Authorization: getCookie('accessToken'),
    //   // Any other headers you need
    // },
  },
);

export const getRecordingListResetAction = () => ({
  type: GET_VOICE_RECORDING_RESET,
});

export function getRecordingListReducer(state, action = null) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case GET_VOICE_RECORDING_RESET: {
      return INITIAL_STATE;
    }
    case GET_VOICE_RECORDING: {
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
        flag: false,
      };
    }
    case GET_VOICE_RECORDING_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true,
      };
    }
    case GET_VOICE_RECORDING_FAILURE: {
      handleLogout();
      return {
        ...state,
        data: null,
        loading: false,
        error: true,
        flag: false,
      };
    }
    default:
      return state;
  }
}
