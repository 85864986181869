/* eslint-disable no-debugger */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import { faL } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Auth0Lock from 'auth0-lock';
import auth0 from 'auth0-js';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie, setCookie } from '../../common/utils';
import { getRecordingListAction } from '../VoiceRecording/logic';

function LoggedIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const voiceRecordData = useSelector((state) => state?.getRecordingList);

  // const [showSignUpQuestionModal, setShowSignUpQuestionModal] = useState(false);
  // const [showSignUpPasswordModal, setShowSignUpPasswordModal] = useState(false);
  // const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => { dispatch(getRecordingListAction({})); }, []);
  useEffect(() => {
    if (voiceRecordData?.data?.voice_recorded !== undefined) {
      if (voiceRecordData?.data?.voice_recorded) {
        // navigate('/processing-status');
        navigate('/song-list');
        // dispatch(getProcessedSongListAction({}));
      } else {
        navigate('/about');
      }
    }
  }, [voiceRecordData?.data?.voice_recorded]);
  return (
    <div className="landing-page">
      {/* {!showModalContents && ( */}
      <div className="landing-page-firstview">
        <Row className="row">
          <Col span={24} className="section-1">
            <div className="logos">
              <img className="lullabai-logo" src="/images/logo-ai.png" />
              <img className="alembic-icon" src="/images/alembic-icon.svg" />
            </div>
          </Col>
        </Row>
      </div>
      {/* )} */}
      {/* {!!showModalContents && (
        <div
          className={`new-landing-page ${
            showSignLoginModal
              ? 'show-signup-background'
              : 'new-landing-page welcome-page'
          }`}
        >
          <Row className="row">
            <Col
              span={10}
              className={
                showAbout ? 'section-1 divider-none' : 'section-1 divider'
              }
            >
              {!!showModalContents && !!showSignLoginModal && (
                <div className="logos">
                  <img className="lullabai-logo" src="/images/logo-ai.png" />
                  <img
                    className="alembic-icon"
                    src="/images/alembic-icon.svg"
                  />
                </div>
              )}
            </Col>
            <Col
              span={10}
              className={`section-2 ${
                showSignLoginModal ? 'center-content' : ''
              }`}
            >
              {!!showModalContents && !!showAbout && (
                <div className="about-section">
                  <div className="about">
                    <img className="lullabai-logo" src="/images/logo-ai.png" />
                    <div className="desc">
                      Welcome to LullbaAl, a web-based platform that is designed
                      for mothers with speech impairment who yearn to sing their
                      baby a lullaby.
                      <br />
                      <br />
                      Powered with Al, it uses the texture of the voice and the
                      essence of the mother’s warmth to recreate melodious
                      lullabies from a pre-recorded list of songs.
                    </div>
                  </div>
                  <div className="next-btn-wrap">
                    <img
                      className="alembic-icon"
                      src="/images/alembic-icon.svg"
                    />
                  </div>
                </div>
              )}
              {!!showModalContents && !!showSignLoginModal && (
                <div className="login-table">
                  <div className="heading" onClick={() => navigate('/signup')}>
                    Sign up for free
                  </div>
                  <div className="login-table-content">
                    <div
                      className="login-table-options"
                      onClick={() => navigate('/signup')}
                    >
                      Continue with Email ID
                    </div>
                    <div
                      className="login-table-options-borderless"
                      onClick={() => setAuthModal(true)}
                    >
                      Already a user? &nbsp;
                      <span style={{ color: '#ff6000' }}>Login</span>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      )} */}
    </div>
  );
}

export default LoggedIn;
