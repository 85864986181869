import { genericActionCreator } from '../../app/epics';
// import { stringify } from 'query-string';
// import { apiCallwithoutCT } from '../../../utils/ApiClient'; // Import apiCallwithoutCT from the correct path

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  flag: false,
};

const GET_PLAYABLE_SONG = 'GET_PLAYABLE_SONG';
const GET_PLAYABLE_SONG_SUCCESS = 'GET_PLAYABLE_SONG_SUCCESS';
const GET_PLAYABLE_SONG_FAILURE = 'GET_PLAYABLE_SONG_FAILURE';
const GET_PLAYABLE_SONG_RESET = 'GET_PLAYABLE_SONG_RESET';

export const getPlayableSongAction = (payload) => genericActionCreator(
  GET_PLAYABLE_SONG,
  payload.params,
  {
    url: `${process.env.apiUrl}v0/download_predefined_song`,
    method: 'GET',
    auth: true,
    // headers: {
    //   // Add the Authorization header with the bearer token
    //   Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    //   // Any other headers you need
    // },
  },
);

export const getPlayableSongResetAction = () => ({
  type: GET_PLAYABLE_SONG_RESET,
});

export function getPlayableSongReducer(state, action = null) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case GET_PLAYABLE_SONG_RESET: {
      return INITIAL_STATE;
    }
    case GET_PLAYABLE_SONG: {
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
        flag: false,
      };
    }
    case GET_PLAYABLE_SONG_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true,
      };
    }
    case GET_PLAYABLE_SONG_FAILURE: {
      return {
        ...state,
        data: null,
        loading: false,
        error: true,
        flag: false,
      };
    }
    default:
      return state;
  }
}
