import { combineReducers } from '@reduxjs/toolkit';
import { modalReducer } from './Modal/logic';
import { sessionReducer } from './Status/logic';
import { customNotificationReducer, notificationReducer } from '../common/Notification/logic';
import { getImagesReducer } from './pics/Pics.logic';
import { getProcessedSongListReducer } from '../components/SongList/logic';
import { getRecordingListReducer, uploadVoiceRecordingReducer } from '../components/VoiceRecording/logic';
import { getPlayableSongReducer } from '../components/PlaySong/logic';
import { postSignupReducer } from '../components/SignUp/logic';

const rootReducer = combineReducers({
  modal: modalReducer,
  session: sessionReducer,
  notification: notificationReducer,
  customNotification: customNotificationReducer,
  images: getImagesReducer,
  getProcessedSongListData: getProcessedSongListReducer,
  uploadVoiceRecordingData: uploadVoiceRecordingReducer,
  playableSongData: getPlayableSongReducer,
  postSignUpData: postSignupReducer,
  getRecordingList: getRecordingListReducer,

});

export default rootReducer;
