/* eslint-disable no-unused-vars */
import { genericActionCreator } from '../../app/epics';

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  flag: false,
};

const GET_VOICE_RECORDING = 'GET_VOICE_RECORDING';
const GET_VOICE_RECORDING_RESET = 'GET_VOICE_RECORDING_RESET';

export const getProcessedSongListAction = (payload) => genericActionCreator(
  GET_VOICE_RECORDING,
  payload.params,
  {
    url: `${process.env.apiUrl}v0/get_song_list`,
    method: 'GET',
    auth: true,
  },
);

export const getProcessedSongListResetAction = () => ({
  type: GET_VOICE_RECORDING_RESET,
});
