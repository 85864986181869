/* eslint-disable no-return-assign */
/* eslint-disable no-constant-condition */
/* eslint-disable eqeqeq */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */
import { AudioOutlined, CaretRightFilled } from '@ant-design/icons';
import {
  Button, Carousel, Col, Row,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStopwatch } from 'react-timer-hook';
import Hamburger from '../Menu';
import ProgressBar from '../ProgressBar';
import SvgLoader from '../../common/SvgLoader';
import { getPlayableSongAction } from '../PlaySong/logic';
import { getProcessedSongListAction } from '../SongList/logic';
import ErrorPage from '../../common/ErrorPage';
import ErrorPageNew from '../../common/ErrorPageNew';
import { getCookie } from '../../common/utils';

function VoiceProcessingStatus() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);

  let showRecording;
  const { data: getProcessedSongListData } = useSelector((state) => state.getProcessedSongListData);

  // use this for permission to start recording
  const [processingStatus, setProcessingStatus] = useState();

  useEffect(() => {
    if (!getCookie('accessToken')) {
      navigate('/', { replace: true });
    }

    if (urlSearchParams.get('status') === 'uploaded') {
      setProcessingStatus('UPLOADED');
    } else {
      dispatch(getProcessedSongListAction({}));
    }
  }, []);

  useEffect(() => {
    if (getProcessedSongListData?.length > 0) {
      let allCompleted = true;
      let allNotDone = true;

      getProcessedSongListData.forEach((e) => {
        if (e?.inference_status === 'COMPLETED') {
          setProcessingStatus('COMPLETED');
        } else if (e?.inference_status === 'NOT_DONE') {
          allCompleted = false; // At least one song is not COMPLETED
          setProcessingStatus('NOTDONE');
        } else {
          // If any song is neither COMPLETED nor NOT_DONE
          allCompleted = false;
          allNotDone = false;
          setProcessingStatus('PENDING');
        }
      });

      // Set PENDING if all songs are neither COMPLETED nor NOT_DONE
      if (!allCompleted && !allNotDone) {
        setProcessingStatus('PENDING');
      }
    }
  }, [getProcessedSongListData?.length]);

  // console.log(processingStatus);

  // const handleStartRecording = () => {
  //   console.log('Recording started');
  //   // You can add any other logic you need here
  // };

  return (
    <div className="voice-recording">
      <Row className="row">
        <Col span={12} className="section-1 divider">
          <div className="logos">
            <img className="lullabai-logo" src="/images/logo-ai.png" />
            {' '}
            <img className="alembic-icon" src="/images/alembic-icon.svg" />
          </div>
        </Col>
        <Hamburger isOpen={false} className="hamberger-icon" />

        <Col span={12} className="section-2">
          {processingStatus === 'UPLOADED' && (
          <div className="column-section">
            {' '}
            <div className="heading">Your LullabAI is being processed.</div>

            <div className="section-2-processing">

              <div className="text">
                This may take 40 minutes or more.
                <br />
                We will notify you when your
                {' '}
                <br />
                {' '}
                LullabAI is read for your baby.
                <br />
                Thank you for your patience.
              </div>

              <ProgressBar
                bgcolor="#FF6000"
                progressStatus="90"
                height={50}
              />
              {/* <Button className="next" onClick={() => { setRecordingInstructions(false); }}>
                Next
              </Button> */}
            </div>
          </div>

          )}

          {processingStatus === 'PENDING' && (
          <div className="column-section">
            {' '}
            <div className="heading">Your LullabAI is being processed</div>

            <div className="section-2-processing">

              <div className="text">
                This may take 40 minutes or more.
                <br />
                We will notify you when your
                {' '}
                <br />
                {' '}
                LullabAI is read for your baby.
                <br />
                Thank you for your patience.
              </div>

              <ProgressBar
                bgcolor="#FF6000"
                progressStatus="90"
                height={50}
              />
              {/* <Button className="next" onClick={() => { setRecordingInstructions(false); }}>
                Next
              </Button> */}
            </div>
          </div>

          )}
          {processingStatus === 'NOTDONE' && (
          <div className="section-2-processing failed">

            <div className="heading">OOPS!</div>
            <div className="result">Sorry, something went wrong an unexpected error has occurred.</div>
            <button
              className="next"
              type="button"
              onClick={() => navigate('/select-song')}
            >
              Try Again
            </button>
          </div>
          )}
          {/* {processingStatus === 'COMPLETED' && (
          <div className="section-2-processing failed">

            <div className="heading">Congratulations!</div>
            <div className="result">Your LullabAI is successfully processed.</div>
            <button
              className="next"
              type="button"
              onClick={() => navigate('/song-list')}
            >
              Click to listen
            </button>
          </div>
          )} */}
        </Col>
      </Row>
    </div>
  );
}

export default VoiceProcessingStatus;
