import { getCookie, removeCookies } from '../../common/utils';

const SET_SESSION = 'SET_SESSION';
const REMOVE_SESSION = 'REMOVE_SESSION';

const INTIAL_STATE = getCookie('accessToken') || '';

export const setSession = (payload) => ({
  type: SET_SESSION,
  payload,
});

export const removeSession = () => ({
  type: REMOVE_SESSION,
});

export const sessionReducer = (state = INTIAL_STATE, action = null) => {
  if (action.type === SET_SESSION) {
    return action.payload;
  }
  if (action.type === REMOVE_SESSION) {
    removeCookies();
    localStorage.clear();
    return '';
  }
  return state;
};
