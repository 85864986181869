import React, { useEffect } from 'react';
import { Carousel, Col, Row } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Hamburger from '../Menu';
import { getCookie } from '../../common/utils';

function About() {
  const navigate = useNavigate();
  const cardContent = [
    'Welcome to LullabAI, brought to you by Alembic. We are dedicated to women’s well-being. We believe in creating awareness about their health issues and in promoting inclusivity and a better future for them.',
    'Lullabies have a deep-rooted cultural significance in India. It creates a strong and unique bond between the mother and child. And helps in the child’s cognitive development.',
    'To ensure no mother misses out on this experience, LullabAI, a web-based mobile platform is created to help mothers with speech impairment.',
    'With the help of AI, this platform gives voice to every mother’s lullaby, enriching their special experience.',
    'If you are a speech-impaired mother or unable to sing to your child, LullabAI is here for you.',
  ];

  useEffect(() => {
    if (!getCookie('accessToken')) {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <div className="new-landing-page1">
      <Row className="row">
        <Col span={12} className="section-1">
          <div className="logos">
            <img className="lullabai-logo" src="/images/logo-ai.png" alt="LullabAI Logo" />
            <img className="lullabai-mobile-logo" src="/images/mobile-logo-ai.png" alt="LullabAI Logo" />
            <img className="alembic-icon" src="/images/alembic-icon.svg" alt="Alembic Icon" />
          </div>
        </Col>
        <Col span={4} className="middle-section" />
        <Hamburger isOpen={false} className="hamberger-icon" />

        <Col span={8} className="section-2">
          <div className="card-content">
            <Carousel
              arrows
              prevArrow={<LeftOutlined className="left-arrow" />}
              nextArrow={<RightOutlined className="right-arrow" />}
              dots={false}
            // infinite={false}
            >
              {cardContent.map((content) => (
                <div className="about">
                  <div className="desc">{content}</div>
                </div>
              ))}
            </Carousel>
            <button
              className="skip"
              type="submit"
              onClick={() => {
                navigate('/instructions');
              }}
            >
              Skip
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default About;
