/* eslint-disable no-unused-vars */
import Rcookie from 'react-cookies';
import queryString from 'query-string';
import auth0, { WebAuth } from 'auth0-js';
import { Navigate, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
// import { stringify } from 'query-string/base';

export const PATHS = [
  ...Object.keys(process.env.APPS).map((item) => process.env.APPS[item].mainRoute),
];

export const setCookie = (value, key) => {
  const { domain } = process.env;
  const expires = new Date();
  expires.setDate(new Date().getDate() + 1);
  Rcookie.save(`${process.env.app}_${key}_${process.env.type}`, value, { path: '/', domain, expires });
};

export const removeCookies = (flag) => {
  const cookies = document.cookie.split(';');
  const { domain } = process.env;
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    PATHS.forEach((item) => {
      if (flag) {
        if (name.includes(`${process.env.app}_`)) {
          document.cookie = `${name} =;expires=Thu, 01 Jan 1970 00:00:00 GMT ; domain=${domain}; path=${item}`;
        }
      } else {
        document.cookie = `${name} =;expires=Thu, 01 Jan 1970 00:00:00 GMT ; domain=${domain}; path=${item}`;
      }
      return 0;
    });
  }
};

// export const generateHashUrl = (value, navigate) => {
//   navigate(`/search?${queryString.stringify(value)}`, { replace: true });
//   navigate(0);
// };

export const getCookie = (key, app) => Rcookie.load(`${app || process.env.app}_${key}_${process.env.type}`);

export function ucFirst(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}
const webAuth = new auth0.WebAuth({
  domain: process.env.auth0.domain,
  clientID: process.env.auth0.clientId,
  audience: process.env.auth0.audience,
  response_type: 'token id_token',
});

export const handleLogout = () => {
  if (window.location.pathname !== '/signup') {
    removeCookies('accessToken');
    removeCookies('permissions');
    localStorage.removeItem('loggedin');
    webAuth.logout({
      returnTo: process.env.appUrl,
      clientID: process.env.auth0.clientId,
    });
    // lock.logout();
    // const navigate = useNavigate();
    toast.error('session expire please relogin', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    setTimeout(() => {
      Navigate('/');
    }, [2000]);
  }
};

export default function apiCall(url, method, body = {}, authReq = true, customHeaders = {}) {
  let obj = {};
  if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
    obj = {
      method,
      url,
      body,
    };
  } else {
    obj = {
      method,
      url: `${url}${body && Object.keys(body).length ? `?${queryString.stringify(body)}` : ''}`,
    };
  }

  const headers = {
    Authorization: (Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : handleLogout()),

    // Authorization: (Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : handleLogout()),
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
    ...customHeaders,
  };
  Object.entries(headers).forEach((en) => {
    if (en[1] === undefined || (!authReq && en[0] === 'Authorization')) {
      delete headers[en[0]];
    }
  });
  return ({
    ...obj,
    headers,
  });
}

// export function apiCallwithoutCT(url, method, body = {}) {
//   let obj = {};
//   if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
//     obj = {
//       method,
//       url,
//       body,
//     };
//   } else {
//     obj = {
//       method,
//       url: `${url}${body && Object.keys(body).length ? `?${stringify(body)}` : ''}`,
//     };
//   }

//   return ({
//     ...obj,
//     headers: {
//       Authorization: (Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : ''),
//       'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
//     },
//   });
// }

export const checkPermission = (value) => {
  if (getCookie('permissions')) {
    return getCookie('permissions').indexOf(value) > -1;
  }
  return false;
};
