import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from './app/epics';
import notificationMiddleWare from './common/middleware';
import rootReducer from './app/reducer';

const epicMiddleware = createEpicMiddleware();

export default configureStore({
  reducer: rootReducer,
  middleware: [epicMiddleware, notificationMiddleWare],
});

epicMiddleware.run(rootEpic);
