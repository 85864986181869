/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-useless-escape */
import {
  Button, Col, Input, Row,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-mobile-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ArrowLeftOutlined, EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { postSignupAction, postSignupResetAction } from './logic';

function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const getProcessedSongListData = useSelector((state) => state.getProcessedSongListData);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showSignUpQuestionModal, setShowSignUpQuestionModal] = useState(false);
  const [showSignUpPasswordModal, setShowSignUpPasswordModal] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [email, setEmail] = useState();
  const [password1, setPassword] = useState();
  const [dob, setDob] = useState(new Date());
  const signUpData = useSelector((state) => state.postSignUpData);
  const [showAgeError, setShowAgeError] = useState(false);
  const [showCongratulationsMsg, setShowCongratulationsMsg] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const passwordRef = useRef('');

  const handleEmail = (e) => {
    setShowSignUpQuestionModal(true);
    setShowSignUpPasswordModal(true);
    setEmail(e?.target[0]?.value);
  };

  // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()_+=\{\}|~:;,.\/])[A-Za-z\d@$!%*?&#^()_+=\{\}|~:;,.\/]{8,}$/;

  const handlePassword = (e) => {
    // setShowDatePicker(true);
    if (passwordRegex.test(e?.target?.value)) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
    setPassword(e?.target?.value);
  };

  function isUser18OrOlder(birthDateString) {
    const birthDate = new Date(birthDateString);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1;
    }
    return age >= 18;
  }

  const handleSelect = (date) => {
    setDob(date);
    const data = {
      dob: date.toString(),
      email,
      name: email,
      // password,
    };
    if (isUser18OrOlder(date)) {
      setShowAgeError(false);
      dispatch(postSignupAction(data));
      // Note: please update this api call accordingly
      setShowDatePicker(false);
      setShowCongratulationsMsg(true);
    } else {
      setShowAgeError(true);
    }
  };
  const handleNext = () => {
    const data = {
      dob: 'Sat Jul 22 2000 11:19:10 GMT+0530 (India Standard Time)',
      email,
      name: 'test',
      password: password1,
    };
    dispatch(postSignupAction(data));
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (signUpData.flag) {
      setShowSignUpPasswordModal(false);
      setShowCongratulationsMsg(true);
      toast.success('Signup Successful', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    if (signUpData.error) {
      navigate('/');
      toast.error('Email Already Registered', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    dispatch(postSignupResetAction());
  }, [signUpData]);

  const monthMap = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  const dateConfig = {
    year: {
      format: 'YYYY',
      caption: 'Year',
      step: 1,
    },
    month: {
      format: (value) => monthMap[value.getMonth() + 1],
      caption: 'Mon',
      step: 1,
    },
    date: {
      format: 'DD',
      caption: 'Day',
      step: 1,
    },
  };
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <div className="login-page">
      <Row className="row">
        <Col span={10} className="section-1 divider">
          <div className="logos">
            <img className="lullabai-logo" src="/images/logo-ai.png" />
            <img className="alembic-icon" src="/images/alembic-icon.svg" />
          </div>
        </Col>
        <Col span={10} className="section-2">
          <div className="heading-text">
            {!showSignUpQuestionModal ? 'Create an account' : showSignUpPasswordModal ? 'Create a password' : showCongratulationsMsg ? (
              <>
                Congratulations!
                <br />
                <br />
                Your LullabAI account has
                <br />
                been created
              </>
            ) : ''}
          </div>

          {!showSignUpQuestionModal && (
            <form className="login-table-form-wrap" onSubmit={handleEmail}>
              <div className="login-table">
                {/* <div className="question">What’s your email address?</div> */}
                <div className="note">
                  <ArrowLeftOutlined className="back-btn" onClick={() => { navigate('/'); }} />
                  Enter your email address to continue
                </div>
                <div className="password-sec-combine">
                  <input type="email" required autoFocus />
                  <button className="next" type="submit">Next</button>
                </div>
              </div>
            </form>
          )}
          {!!showSignUpPasswordModal && (
            <form className="login-table-form-wrap">
              <div className="login-table">
                <div className="note">
                  <ArrowLeftOutlined className="back-btn" onClick={() => { setShowSignUpPasswordModal(false); setShowSignUpQuestionModal(false); }} />
                  Your password should at least be 8 characters long. Use a combination of uppercase and lowercase letters,
                  numbers, and symbols.
                </div>
                <div className="password-sec-combine">
                  <div className="password-input-container">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()_+=\{\}|~:;,.\/])[A-Za-z\d@$!%*?&#^()_+=\{\}|~:;,.\/]{8,}$"
                      required
                      autoFocus
                      onChange={handlePassword}
                      style={{ paddingRight: '45px' }}
                      ref={passwordRef}
                    />
                    <button
                      type="button"
                      className="toggle-password"
                      onClick={togglePasswordVisibility}
                      aria-label={showPassword ? 'Hide password' : 'Show password'}
                      aria-pressed={showPassword}
                    >
                      {showPassword ? <EyeFilled /> : <EyeInvisibleFilled />}
                    </button>
                  </div>
                  {!isPasswordValid && passwordRef?.current?.value?.length > 0 && (<div className="password-error">Password invalid</div>)}
                  <button className="next password-next" type="button" disabled={!isChecked || !isPasswordValid || passwordRef?.current?.value?.length === 0} onClick={() => handleNext()}>Next</button>
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <div className="note">I am 18 years and above</div>
                  </div>
                </div>
              </div>

            </form>
          )}

          {/* {!!showDatePicker && (
            <div className="login-table-form-wrap">
              <div className="login-table">
                <div className="question">What is your date of birth</div>
                {showAgeError && <div className="age-error">Age needs to be more than 18years</div>}
                <DatePicker
                  value={dob}
                  onSelect={handleSelect}
                  isPopup={false}
                  showHeader={false}
                  confirmText="Next"
                  cancelText={false}
                  dateConfig={dateConfig}
                  className="datepicker-component"
                />
                {/* <DatePicker className="birthdate-picker" format={['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY']} /> */}
          {/* <Button className="next">Next</Button> */}
          {' '}

          {/* </div> */}
          {/* </div> */}
          {/* )} */}
          {showCongratulationsMsg && (
            <div className="login-table-form-wrap">
              <div className="login-table">
                <div className="password-sec-combine">
                  <div className="note">
                    Get ready for a heartwarming experience
                  </div>
                  <button className="next" type="button" onClick={() => navigate('/')}>Next</button>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default SignUp;
