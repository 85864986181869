/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/media-has-caption */
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getProcessedSongListAction } from '../SelectSong/logic';
import { getCookie } from '../../common/utils';

function Instructions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signUpData = useSelector((state) => state.postSignUpData);
  const [showPlayButton, setShowPlayButton] = useState(true);

  useEffect(() => {
    if (!getCookie('accessToken')) {
      navigate('/', { replace: true });
    }

    setTimeout(() => {
      setShowPlayButton(false);
    }, 2000);
  }, []);
  useEffect(() => {
    if (signUpData.flag) {
      toast.success('Signup Successful', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      navigate('/');
    }
    if (signUpData.error) {
      navigate('/');
      toast.error('Email Already Registered', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [signUpData]);

  return (
    <div className="instruction-page">
      <Row className="row">
        <Col span={12} className="section-1 divider">
          <div className="logos">
            <img className="lullabai-logo" src="/images/logo-ai.png" />
            <img className="alembic-icon" src="/images/alembic-icon.svg" />
          </div>
        </Col>
        <Col span={12} className="section-2">
          <div className="heading-text">How to record your LullabAI?</div>

          <div className="instruction-container-wrap">
            <div className="instrictions-outer-container">
              {showPlayButton ? (
                <div className="instructions-inner-container">
                  <img src="/images/play-btn.png" />
                </div>
              ) : (
                <div className="">
                  <video src="https://lullabai.forherwithher.in/video/VID-20240722-WA0000.mp4" controls>
                    <source
                      src="https://lullabai.forherwithher.in/video/VID-20240722-WA0000.mp4"
                      type="video/mp4"
                    />
                    {/* <p>
                      Your browser does not support HTML video. Here is a
                      <a href="https://lullabai.forherwithher.in/video/VID-20240722-WA0000.mp4" download="https://lullabai.forherwithher.in/video/VID-20240722-WA0000.mp4">link to the video</a>
                      {' '}
                      instead.
                    </p> */}
                  </video>
                </div>
              )}

              <button
                type="button"
                className="next"
                onClick={() => {
                  navigate('/select-song'); dispatch(getProcessedSongListAction({}));
                }}
              >
                Next
              </button>
            </div>
          </div>
        </Col>
        <Col span={12} className="mobile-section-3 divider">
          <div className="logos">
            <img className="alembic-icon" src="/images/alembic-icon.svg" />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Instructions;
