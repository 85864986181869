/* eslint-disable no-unused-vars */
import { genericActionCreator } from '../../app/epics';
import { getCookie } from '../../common/utils';
// import { stringify } from 'query-string';
// import { apiCallwithoutCT } from '../../../utils/ApiClient'; // Import apiCallwithoutCT from the correct path

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  flag: false,
};

const SIGNUP = 'SIGNUP';
const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
const SIGNUP_RESET = 'SIGNUP_RESET';

export const postSignupAction = (payload) => genericActionCreator(
  SIGNUP,
  payload,
  {
    url: `${process.env.apiUrl}v0/signup`,
    method: 'POST',
    auth: true,
  },
);

export const postSignupResetAction = () => ({
  type: SIGNUP_RESET,
});

export function postSignupReducer(state, action = null) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case SIGNUP_RESET: {
      return INITIAL_STATE;
    }
    case SIGNUP: {
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
        flag: false,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true,
      };
    }
    case SIGNUP_FAILURE: {
      return {
        ...state,
        data: null,
        loading: false,
        error: true,
        flag: false,
      };
    }
    default:
      return state;
  }
}
