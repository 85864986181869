/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-unresolved */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Bounce, ToastContainer } from 'react-toastify';
import store from './store';
// import ErrorPageNew from './common/ErrorPageNew';
import ErrorPage from './common/ErrorPage';
import '../public/styles/main.scss';
// import LandingPage from './components/Landi÷ngPage';
import './app.css';
// import LandingPage from './components/LandingPage';
// import LoginPage from './components/LoginPage';
// import LandingPageBrowser from './components/LandingPage1';
import VoiceRecording from './components/VoiceRecording';
import NewLandingPage from './components/LandingPage';
import SongList from './components/SongList';
import SignIn from './components/LoginPage';
import SignUp from './components/SignUp';
import PlaySong from './components/PlaySong';
import YourPlaylist from './components/Playlist';
import About from './components/About';
import Instructions from './components/Instructions';
import SelectSong from './components/SelectSong';
import Congratulations from './components/Congratulations';
import VoiceProcessingStatus from './components/VoiceProcessingStatus';
import LoggedIn from './components/LoggedIn';
import LoggedInAbout from './components/LoggedInAbout';
import FAQ from './components/FAQ';
// import PlayingSong from './components/PlayingSong';
// import CustomDatePicker from './components/datePicker/datepicker';

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

const router = createBrowserRouter([

  {
    path: '/',
    element: <NewLandingPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/processing-status',
    element: <VoiceProcessingStatus />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/signup',
    element: <SignUp />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/about',
    element: <About />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/loggedin',
    element: <LoggedIn />,
    errorElement: <ErrorPage />,
  },

  {
    path: '/voice-recording',
    element: <VoiceRecording />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/song-list',
    element: <SongList />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/play-song',
    element: <PlaySong />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/play-list',
    element: <YourPlaylist />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/instructions',
    element: <Instructions />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/select-song',
    element: <SelectSong />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/congratulations',
    element: <Congratulations />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: <SignIn />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/logged-about',
    element: <LoggedInAbout />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/faq',
    element: <FAQ />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: '/playing-song',
  //   element: <PlayingSong />,
  //   errorElement: <ErrorPage />,

  // }
]);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <RouterProvider router={router} />
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      transition={Bounce}
    />
  </Provider>,
  // </React.StrictMode>,
);
