/* eslint-disable no-return-assign */
/* eslint-disable no-constant-condition */
/* eslint-disable eqeqeq */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */

import {
  Col, Row,
} from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Hamburger from '../Menu';
import { getCookie } from '../../common/utils';

export default function Congratulations() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!getCookie('accessToken')) {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <div className="congratulations-page-wrapper">
      <Row className="row">
        <Col span={12} className="section-1">
          <div className="logos">
            <img className="lullabai-logo" src="/images/logo-ai.svg" />
            {' '}
            <img className="alembic-icon" src="/images/alembic-icon.svg" />
          </div>
        </Col>
        <Hamburger isOpen={false} className="hamberger-icon" />

        <Col span={12} className="section-2">
          {true && (
          <div className="section-2-processing">

            <div className="heading">Congratulations!</div>
            <div>Your LullabAI is successfully processed.</div>
            <button
              type="button"
              className="click-to-listen-btn"
              onClick={() => {
                navigate('../song-list');
              }}
            >
              Click to listen
            </button>
          </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
