import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, Row } from 'antd';
import axios from 'axios';
import Rcookie from 'react-cookies';
import {
  CaretRightFilled,
  LoadingOutlined,
  PauseOutlined,
} from '@ant-design/icons';
// import AudioPlayer from 'react-h5-audio-player';
import { getProcessedSongListAction } from './logic';
import Hamburger from '../Menu';
import { getCookie } from '../../common/utils';

function SelectSong() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signUpData = useSelector((state) => state.postSignUpData);
  const { data: getProcessedSongListData } = useSelector(
    (state) => state.getProcessedSongListData,
  );
  const [cachedSongs, setCachedSongs] = useState({}); // State to cache song URLs
  const [value, setValue] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingSongIdx, setLoadingSongIdx] = useState(null); // Track loading song index
  const [playingSongId, setPlayingSongId] = useState(null);
  const audioRef = useRef(new Audio());
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const [songLoadingCount, setSongLoadingCount] = useState(0);

  useEffect(() => {
    if (!getCookie('accessToken')) {
      navigate('/', { replace: true });
    }
  }, []);

  // Fetch initial song list on component mount
  useEffect(() => {
    dispatch(getProcessedSongListAction({}));
  }, [dispatch]);
  // Function to play or pause audio
  const handlePlayPause = (idx, audioUrl) => {
    if (isPlaying && playingSongId === idx) {
      audioRef.current.pause();
      setIsPlaying(false);
      setPlayingSongId(null);
    } else {
      audioRef.current.src = audioUrl;
      audioRef.current.load();
      audioRef.current.play();
      setIsPlaying(true);
      setPlayingSongId(idx);
    }
  };

  // Function to handle song selection
  const handleSongSelect = (songId) => {
    setValue(songId);
  };

  // Function to handle next button click
  const handleNextButtonClick = () => {
    setNextPageLoading(true);
    setTimeout(() => {
      audioRef.current.pause();
      setIsPlaying(false);
      setPlayingSongId(null);
      if (value) {
        localStorage.setItem('preferred_song_id', JSON.stringify(value));
        navigate(`/voice-recording?preferred_song_id=${value}`);
      }
      setNextPageLoading(false);
    }, 2000);
  };

  // Handle signup success or error
  useEffect(() => {
    if (signUpData.flag) {
      toast.success('Signup Successful', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      navigate('/');
    }
    if (signUpData.error) {
      navigate('/');
      toast.error('Email Already Registered', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [signUpData, navigate]);

  // Function to play audio from the server or cached URL
  const playAudio = (songData, showType, idx) => {
    if (showType === 'own') {
      const cachedUrl = cachedSongs[idx];
      if (cachedUrl) {
        handlePlayPause(idx, cachedUrl);
      } else {
        setIsLoading(true); // Start loading indicator
        setLoadingSongIdx(idx); // Set loading song index
        setSongLoadingCount((prevCount) => prevCount + 1);
        const endPoint = showType === 'own'
          ? 'download_predefined_song'
          : 'download_inferenced_song';
        axios
          .get(
            `${process.env.apiUrl}v0/${endPoint}?song_id=${songData?.song_id}`,
            {
              headers: {
                Authorization: Rcookie.load(
                  `${process.env.app}_accessToken_${process.env.type}`,
                ),
              },
              responseType: 'arraybuffer',
            },
          )
          .then((response) => {
            const blob = new Blob([response.data], { type: 'audio/mpeg' });
            const audioUrl = URL.createObjectURL(blob);
            setCachedSongs((prevCache) => ({
              ...prevCache,
              [idx]: audioUrl,
            }));
            handlePlayPause(idx, audioUrl);
          })
          .catch((err) => {
            console.error('Error fetching song:', err);
          })
          .finally(() => {
            setIsLoading(false); // Stop loading indicator
            setLoadingSongIdx(null); // Clear loading song index
            setSongLoadingCount((prevCount) => prevCount - 1);
          });
      }
    }
  };

  return (
    getProcessedSongListData && (
      <div className="select-song-page">
        <Row className="row">
          <Col span={12} className="section-1 divider">
            <div className="logos">
              <img
                className="lullabai-logo"
                src="/images/logo-ai.png"
                alt="LullabAI Logo"
              />
              <img
                className="alembic-icon"
                src="/images/alembic-icon.svg"
                alt="Alembic Icon"
              />
            </div>
          </Col>
          <Hamburger isOpen={false} className="hamberger-icon" />

          <Col span={12} className="section-2">
            <div className="heading-text">
              Choose one song to create a LullabAI
            </div>
            <div className="select-song-container-wrap">
              <div className="select-song-list">
                {getProcessedSongListData?.map((song, idx) => (
                  <div className="flex" key={`song-div-${song.song_id}`}>
                    <input
                      type="checkbox"
                      id={song.song_id}
                      name="song"
                      checked={value === song.song_id}
                      value={song.song_id}
                      onChange={() => handleSongSelect(song.song_id)}
                    />
                    <div className="songs">
                      <div className="song-name">
                        {song?.title || `Song ${idx + 1}`}
                      </div>
                      <div className="playing-icons">
                        {isLoading && loadingSongIdx === idx ? (
                          <LoadingOutlined className="play-button" />
                        ) : isPlaying && playingSongId === idx ? (
                          <PauseOutlined
                            className="play-button"
                            onClick={() => handlePlayPause(idx, cachedSongs[idx])}
                            alt="Pause Button"
                          />
                        ) : (
                          <CaretRightFilled
                            className="play-button"
                            onClick={() => playAudio(song, 'own', idx)}
                            alt="Play Button"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}

              </div>
              <button
                className="next"
                type="button"
                onClick={handleNextButtonClick}
                disabled={!value || nextPageLoading || songLoadingCount !== 0}
              >
                {nextPageLoading ? <LoadingOutlined className="play-button" /> : 'Next'}
              </button>
            </div>
          </Col>
        </Row>
      </div>
    )
  );
}

export default SelectSong;
