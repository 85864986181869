/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

function ProgressBar({ bgcolor, progressStatus, height }) {
  const [progress, setProgress] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 1;
        if (newProgress >= 90) {
          clearInterval(interval); // Stop the interval
          navigate('/song-list'); // Navigate to '/song-list'
          return 90; // Ensure progress is capped at 90
        }
        return newProgress; // Increment progress
      });
    }, 100);

    return () => clearInterval(interval); // Cleanup function to clear the interval on unmount
  }, []); // Empty dependency array to run only once on mount

  const Parentdiv = {
    height,
    width: '100%',
    backgroundColor: 'whitesmoke',
    margin: 30,
  };

  const Childdiv = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: bgcolor,
    textAlign: 'right',
  };

  const progresstext = {
    height: '100%',
    color: 'white',
    fontWeight: 900,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    paddingRight: '10px',
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span style={progresstext}>{progress > 30 ? `Loading ${progress}%` : `${progress}%`}</span>
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  bgcolor: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default ProgressBar;
