/* eslint-disable no-self-assign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/react-in-jsx-scope */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import useSize from './useSize';

function animateBars(analyser, canvas, canvasCtx, dataArray, bufferLength) {
  analyser.getByteFrequencyData(dataArray);

  // canvasCtx.fillStyle = '#000';

  const HEIGHT = canvas.height / 2;

  const barWidth = Math.ceil(canvas.width / bufferLength) * 2.5;
  let barHeight;
  let x = 0;

  for (let i = 0; i < bufferLength; i += 1) {
    barHeight = (dataArray[i] / 255) * HEIGHT;
    const blueShade = Math.floor((dataArray[i] / 255) * 5); // generate a shade of blue based on the audio input
    const blueHex = ['white'][
      blueShade
    ]; // use react logo blue shades
    canvasCtx.fillStyle = blueHex;
    canvasCtx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);

    x += barWidth + 1;
  }
}

function WaveForm({ analyzerData }) {
  const canvasRef = useRef(null);
  const { dataArray, analyzer, bufferLength } = analyzerData;
  const [width, height] = useSize();

  const draw = () => {
    const canvas = canvasRef.current;
    if (!canvas || !analyzer) return;
    const canvasCtx = canvas.getContext('2d');

    const animate = () => {
      requestAnimationFrame(animate);
      canvas.width = canvas.width;
      canvasCtx.translate(0, canvas.offsetHeight / 2 - 115);
      animateBars(analyzer, canvas, canvasCtx, dataArray, bufferLength);
    };

    animate();
  };

  useEffect(() => {
    draw();
  }, [dataArray, analyzer, bufferLength]);

  return (
    <canvas
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        zIndex: '-10',
        width: '500px',
      }}
      ref={canvasRef}
      width={width}
      height={height}
    />
  );
}

WaveForm.propTypes = {
  analyzerData: PropTypes.shape({
    dataArray: PropTypes.array.isRequired,
    analyzer: PropTypes.object.isRequired,
    bufferLength: PropTypes.number.isRequired,
  }).isRequired,
};

export default WaveForm;
