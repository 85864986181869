/* eslint-disable no-return-assign */
/* eslint-disable no-constant-condition */
/* eslint-disable eqeqeq */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */
import { AudioOutlined, CaretRightFilled } from '@ant-design/icons';
import {
  Button, Carousel, Col, Row,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStopwatch } from 'react-timer-hook';
import { getRecordingListAction, uploadVoiceRecordingAction, uploadVoiceRecordingResetAction } from './logic';
import Hamburger from '../Menu';
import ProgressBar from '../ProgressBar';
import SvgLoader from '../../common/SvgLoader';
import { getPlayableSongAction } from '../PlaySong/logic';
import { getProcessedSongListAction } from '../SongList/logic';
import { getCookie } from '../../common/utils';

function VoiceRecording() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [recordedAudios, setRecordedAudios] = useState([]);
  const recorderControls = useAudioRecorder();
  const [audioRecorded, setAudioRecorded] = useState(false);
  const [gotoNext, setGotoNext] = useState(false);
  const [audioModes, setAudioModes] = useState(false);
  const [recordingInstructions, setRecordingInstructions] = useState(false);

  const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };
  const {
    totalSeconds,
    seconds,
    minutes,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });
  const formatTime = (time) => String(time).padStart(2, '0');
  const uploadedRecordedData = useSelector((state) => state?.uploadVoiceRecordingData);
  const voiceRecordData = useSelector((state) => state?.getRecordingList);
  const urlSearchParams = new URLSearchParams(location.search);
  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    setRecordedAudios([...recordedAudios, url]);
    setTimeout(() => {
      setAudioRecorded(true);
    }, 500);
  };
  const [processingStatus, setProcessingStatus] = useState();
  const [uploadAudio, setUploadAudio] = useState(false);
  const devanagariCharacters = [
    'क', 'ख', 'ग', 'घ', 'ङ', 'च', 'छ', 'ज', 'झ', 'ञ', 'ट', 'ठ', 'ड', 'ढ', 'ण', 'त', 'थ', 'द', 'ध',
    'न', 'प', 'फ', 'ब', 'भ', 'म', 'य', 'र', 'ल', 'व', 'श', 'ष', 'स', 'ह', 'क्ष', 'त्र', 'श्र', 'ज्ञ',
  ];
  const [isRecording, setIsRecording] = useState(false);
  let showRecording;
  const { data: getProcessedSongListData } = useSelector((state) => state.getProcessedSongListData);

  // use this for permission to start recording
  const [enableRecording, setEnableRecording] = useState(false);
  const [isRecSubmitDisabled, setIsRecSubmitDisabled] = useState(false);

  // Mic Permission Changes ongoing
  const [hasMicrophoneAccess, setHasMicrophoneAccess] = useState(null);
  const [permissionStatus, setPermissionStatus] = useState(null);
  const [error, setError] = useState('');

  // Mic Permission Changes ongoing
  const requestMicrophoneAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setHasMicrophoneAccess(true);
      setPermissionStatus(true);
      // Optionally, you can stop the stream after getting the permission
      stream.getTracks().forEach((track) => track.stop());
    } catch (err) {
      // Handle the error if access is denied or other issues
      setHasMicrophoneAccess(false);
      setError('Microphone access denied or an error occurred.');
    }
  };

  // Mic Permission Changes ongoing
  const checkMicrophonePermission = async () => {
    try {
      const permission = await navigator.permissions.query({ name: 'microphone' });
      setPermissionStatus(permission.state);
      permission.onchange = () => {
        setPermissionStatus(permission.state);
      };
    } catch (err) {
      // Handle the case where permissions API is not supported or other errors
      console.log('Permissions API is not supported in this browser.');
    }
  };

  useEffect(() => {
    if (!getCookie('accessToken')) {
      navigate('/', { replace: true });
    }

    dispatch(getRecordingListAction({}));
    dispatch(getProcessedSongListAction({}));

    showRecording = urlSearchParams.get('showRecording') || 'false';

    // Mic Permission Changes ongoing
    checkMicrophonePermission();

    return () => {
      setUploadAudio(false);
    };
  }, []);

  useEffect(() => {
    if (!urlSearchParams.get('preferred_song_id')) {
      if (getProcessedSongListData?.length > 0) {
        let allCompleted = true;
        let allNotDone = true;

        getProcessedSongListData.forEach((e) => {
          if (e?.inference_status === 'COMPLETED') {
            setProcessingStatus('COMPLETED');
          } else if (e?.inference_status === 'NOT_DONE') {
            allCompleted = false; // At least one song is not COMPLETED
            setProcessingStatus('NOTDONE');
          } else {
          // If any song is neither COMPLETED nor NOT_DONE
            allCompleted = false;
            allNotDone = false;
            setProcessingStatus('PENDING');
            // navigate('/processing-status');
          }
        });

        // Set PENDING if all songs are neither COMPLETED nor NOT_DONE
        if (!allCompleted && !allNotDone) {
          setProcessingStatus('PENDING');
        }
      }
    }
  }, [getProcessedSongListData]);

  console.log(processingStatus);

  useEffect(() => {
    if (recordedAudios.length > 0 && uploadAudio === true) {
      setAudioRecorded(true);
      const formData = new FormData();
      formData.append('files', recordedAudios[0], 'recording.mp3');
      formData.append('preferred_song_id', JSON.parse(localStorage.getItem('preferred_song_id')));
      setUploadAudio(false);
      setIsRecSubmitDisabled(true);
      dispatch(uploadVoiceRecordingAction({
        // params: {
        //   check: 1,
        // },
        body: formData,
      }));
    }
  }, [uploadAudio === true]);

  useEffect(() => {
    if (isRecording) {
      setTimeout(() => {
        setIsRecording(false);
      }, 3000);
    }
  }, [isRecording]);

  useEffect(() => {
    if (uploadedRecordedData?.data?.message === 'File uploaded successfully') {
      setTimeout(() => {
        setRecordedAudios([]);
        dispatch(uploadVoiceRecordingResetAction({}));
        navigate('/processing-status?status=uploaded');
      }, 3000);
    }
  }, [uploadedRecordedData?.data?.message === 'File uploaded successfully']);
  useEffect(() => {
    if (recorderControls.isRecording) { start(); } else {
      pause();
    }
  }, [!!recorderControls.isRecording]);

  // Mic Permission Changes ongoing
  useEffect(() => {
    if (!permissionStatus || permissionStatus == 'prompt' || permissionStatus == 'denied') {
      requestMicrophoneAccess();
    }
  }, [permissionStatus]);

  // const handleStartRecording = () => {
  //   console.log('Recording started');
  //   // You can add any other logic you need here
  // };
  const handleCheckBoxChange = (event) => {
    setEnableRecording(event.target.checked);
  };

  return (
    <div className="voice-recording">
      <Row className="row">
        <Col span={12} className="section-1 divider">
          <div className="logos">
            <img className="lullabai-logo" src="/images/logo-ai.png" />
            {' '}
            <img className="alembic-icon" src="/images/alembic-icon.svg" />
          </div>
        </Col>
        <Hamburger isOpen={false} className="hamberger-icon" />

        <Col span={12} className="section-2">
          {!!audioModes && (
            <div className="section-2-recording">
              <div className="heading">Audio Modes</div>
              <div className="notes">
                <CaretRightFilled style={{ color: '#FF6000' }} />
                Ready to play songs in the original voice
              </div>
              <div className="notes">
                <CaretRightFilled />
                Listen in your own voice takes 2-3 minutes
                for processing
              </div>
              <Button className="next" onClick={() => { setAudioModes(false); setRecordingInstructions(true); }}>
                Next
              </Button>

            </div>

          )}
          {!!recordingInstructions && (
            <div className="section-2-recording">
              <div className="heading">Recording Instructions</div>
              <div className="notes">
                <div className="circle">
                  {/* <img src="/images/watch.svg" /> */}
                  <SvgLoader svgName="watch" width={40} height={40} />
                </div>
                Record your voice for at least 30 seconds
                {' '}
              </div>
              <div className="notes">
                <div className="circle">
                  <SvgLoader svgName="mute" width={40} height={40} />
                </div>
                {' '}
                Choose a quiet location
              </div>
              <div className="notes">
                <div className="circle">
                  <SvgLoader svgName="mic" width={40} height={40} />
                </div>
                {' '}
                Keep microphone close to your mouth
                {' '}
              </div>
              <div className="notes">
                <div className="circle">
                  <SvgLoader svgName="breathing" width={40} height={40} />
                </div>
                {' '}
                Control your breathing
                {' '}
              </div>
              <Button className="next" onClick={() => { setRecordingInstructions(false); }}>
                Next
              </Button>

            </div>

          )}

          {/* {!audioRecorded && !audioModes && !recordingInstructions && ( */}
          <div className="section-2-recording">
            <div className="heading">Tap to record and read it aloud</div>
            <div className="time-prompt">
              ( for at least 30 seconds )
              {' '}
            </div>
            <div className="vocal-section">
              <Carousel autoplay className="carousel" dots={false} speed={400}>
                {devanagariCharacters?.map((word, idx) => (
                  <div className="content">
                    <h3>{word}</h3>
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="timer-value-and-text">
              <div>

                <span>{formatTime(minutes)}</span>
                :
                <span>{formatTime(seconds)}</span>
              </div>
              {/* <button onClick={start}>Start</button> */}
              {/* <button onClick={start}>Start</button>
              <button onClick={reset}>Reset</button> */}
            </div>
            {!(permissionStatus == 'denied') && !(permissionStatus == 'prompt') ? (
              <div
                className={isRunning ? 'btn-recording' : 'btn'}
                // onClick={() => console.log('Recording started')}
                // onClick={() => {
                //   if (enableRecording) {
                //     console.log('Abhi');
                //     start();
                //   }
                // }}
                onClick={isRunning ? pause : start}
              >
                {/* <button onClick={pause}>Pause</button> */}

                <AudioOutlined
                  className="btn-audio"
                />
                <AudioRecorder
                  onRecordingComplete={(blob) => {
                    setRecordedAudios((prevRecordedAudios) => [...prevRecordedAudios, blob]); setTimeout(() => {
                      dispatch(getProcessedSongListAction({}));
                    }, 500);
                  }}
                  recorderControls={recorderControls}
                  className="recorder"
                  // onClick={start}
                  downloadFileExtension="mp3"
                />
              </div>
            ) : permissionStatus == 'denied' ? (<div className="mic-pulse">( You have denied microphone permission, enable it to record )</div>) : (<div className="mic-pulse">( Please provide microphone permission to record )</div>) }
            {/* {totalSeconds > 0 && (
            <div>
              The recording must be at least 30 seconds
              {' '}
            </div>
            )} */}

            <div className="permission_wrapper">
              <label htmlFor="permission_checkbox">
                <input value={enableRecording} name="permission_checkbox" className="permission-checkbox" id="permission-checkbox" type="checkbox" onChange={handleCheckBoxChange} />
                  &nbsp;&nbsp;I permit to use my recorded voice to create lullabies
              </label>
            </div>
            {/* <p className="timer-value-and-text">{isRunning ? 'Press To Stop Recording' : 'Press To Start Recording'}</p> */}

            {/* {recordedAudios.map((audioUrl, index) => (
                // <audio key={index} controls src={audioUrl} />
              ))} */}

            <button
              type="button"
              className="next"
              disabled={recordedAudios.length === 0 || !enableRecording || totalSeconds < 30 || isRecSubmitDisabled}
              onClick={() => {
                setUploadAudio(true);
              }}
            >
              Next
            </button>
          </div>
          {/* )} */}
          {/* {processingStatus === 'PENDING' && (
            <div className="section-2-processing">

              <div className="heading">Your LullabAI is being processed</div>
              <div>This may take 40 minutes or more.</div>
              <div>We will notify you when your LullabAI is read for your baby.</div>
              <div>Thank you for your patience.</div>

              <ProgressBar
                bgcolor="#FF6000"
                progressStatus="90"
                height={50}
              />

            </div>
          )} */}

          {!!gotoNext && (
            <div className="section-2-goto-next">
              <div className="heading">
                {' '}
                {uploadedRecordedData?.data?.message === 'File uploaded successfully' ? 'Voice Recorded !' : 'Voice processing failed!'}
                {' '}
              </div>
              <Button className="next" onClick={() => { { uploadedRecordedData?.data?.message === 'File uploaded successfully' ? navigate('/song-list') : window.location.reload(); } }}>
                {uploadedRecordedData?.data?.message === 'File uploaded successfully' ? 'Next' : 'Try Again'}

              </Button>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default VoiceRecording;
