import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function SvgLoader({
  width, height, svgName, className, pointer, hoverIconName, ...rest
}) {
  const clasess = classNames(`${svgName}-icon`, className, `${pointer ? 'pointer' : ''}`);
  const [hover, setHover] = useState(false);

  return (
    <svg
      onBlur={() => setHover(false)}
      onFocus={() => setHover(true)}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      width={width}
   // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      height={height}
      className={clasess}
    >
      <use xlinkHref={`/spritemap.svg#sprite-${hover && hoverIconName !== '' ? hoverIconName : svgName}`} />
    </svg>
  );
}

SvgLoader.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  svgName: PropTypes.string.isRequired,
  className: PropTypes.string,
  hoverIconName: PropTypes.string,
  pointer: PropTypes.bool,
};

SvgLoader.defaultProps = {
  className: '',
  hoverIconName: '',
  pointer: false,
};

export default SvgLoader;
