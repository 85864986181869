/* eslint-disable no-unused-vars */
import { genericActionCreator } from '../../app/epics';
import { getCookie } from '../../common/utils';
// import { stringify } from 'query-string';
// import { apiCallwithoutCT } from '../../../utils/ApiClient'; // Import apiCallwithoutCT from the correct path

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  flag: false,
};

const GET_VOICE_RECORDING = 'GET_VOICE_RECORDING';
const GET_VOICE_RECORDING_SUCCESS = 'GET_VOICE_RECORDING_SUCCESS';
const GET_VOICE_RECORDING_FAILURE = 'GET_VOICE_RECORDING_FAILURE';
const GET_VOICE_RECORDING_RESET = 'GET_VOICE_RECORDING_RESET';

export const getProcessedSongListAction = (payload) => genericActionCreator(
  GET_VOICE_RECORDING,
  payload.params,
  {
    url: `${process.env.apiUrl}v0/get_song_list`,
    method: 'GET',
    auth: true,
    // headers: {
    //   // Add the Authorization header with the bearer token
    //   Authorization: getCookie('accessToken'),
    //   // Any other headers you need
    // },
  },
);

export const getProcessedSongListResetAction = () => ({
  type: GET_VOICE_RECORDING_RESET,
});

export function getProcessedSongListReducer(state, action = null) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case GET_VOICE_RECORDING_RESET: {
      return INITIAL_STATE;
    }
    case GET_VOICE_RECORDING: {
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
        flag: false,
      };
    }
    case GET_VOICE_RECORDING_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true,
      };
    }
    case GET_VOICE_RECORDING_FAILURE: {
      return {
        ...state,
        data: null,
        loading: false,
        error: true,
        flag: false,
      };
    }
    default:
      return state;
  }
}

// export const getProcessedSongListEpic = (actions$) => actions$.pipe(
//   ofType(GET_VOICE_RECORDING),
//   mergeMap(() => ajax(
//     apiCallwithoutCT(
//       '/dashboard/get_state_list',
//       'GET',
//     ),
//   ).pipe(
//     map((res) => ({
//       type: GET_VOICE_RECORDING_SUCCESS,
//       payload: res,
//     })),
//     catchError((err) => of({
//       type: GET_VOICE_RECORDING_FAILURE,
//       payload: err,
//     })),
//   )),
// );

// export const getProcessedSongListDataReducer = {
//   // voiceRecording: voiceRecordingReducer,
//   getProcessedSongListData: getProcessedSongListReducer,
// };

// export default getProcessedSongListDataReducer;
