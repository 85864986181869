/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable camelcase */
/* eslint-disable no-multiple-empty-lines */

// Latest Login Page
import {
  Button, Col, Input, Row,
} from 'antd';
import auth0 from 'auth0-js';
import React, { useEffect, useState } from 'react';
import Rcookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-mobile-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  ArrowLeftOutlined,
  EyeInvisibleFilled,
  EyeFilled,
} from '@ant-design/icons';
import axios from 'axios';
import { getRecordingListAction } from '../VoiceRecording/logic';
import { getCookie, removeCookies, setCookie } from '../../common/utils';
import { removeSession } from '../../app/Status/logic';
// import { postSignupAction, postSignupResetAction } from './logic';

function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const getProcessedSongListData = useSelector((state) => state.getProcessedSongListData);
  const [showPassword, setShowPassword] = useState(false);
  const [showAbout, setShowAbout] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showSignUpQuestionModal, setShowSignUpQuestionModal] = useState(false);
  const [showSignUpPasswordModal, setShowSignUpPasswordModal] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [email, setEmail] = useState();
  const [password1, setPassword] = useState();
  const [dob, setDob] = useState(new Date());
  const signUpData = useSelector((state) => state.postSignUpData);
  const [showAgeError, setShowAgeError] = useState(false);
  const [showCongratulationsMsg, setShowCongratulationsMsg] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);

  const [showModalContents, setShowModalContents] = useState(false);
  const [showLanding, setShowLanding] = useState(true);
  const voiceRecordData = useSelector((state) => state?.getRecordingList);

  const webAuth = new auth0.WebAuth({
    domain: process.env.auth0.domain,
    clientID: process.env.auth0.clientId,
    audience: process.env.auth0.audience,
    response_type: 'token id_token',
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    // Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`)
    //   && dispatch(getRecordingListAction({}));

    // setTimeout(() => {
    //   setShowLanding(false);
    //   setShowModalContents(true);
    //   setShowAbout(true);
    //   if (
    //     (getCookie('accessToken') && !localStorage.getItem('loggedin'))
    //     || (!getCookie('accessToken') && localStorage.getItem('loggedin'))
    //   ) {
    //     localStorage.removeItem('loggedin');
    //     removeSession();
    //     removeCookies();
    //     webAuth.logout({
    //       returnTo: process.env.appUrl,
    //       clientID: process.env.auth0.clientId,
    //     });
    //   }
    // }, 1000);

    // setTimeout(() => {
    //   setShowAbout(false);

    //   if (!getCookie('accessToken') && !localStorage.getItem('loggedin')) {
    //     removeSession();
    //     removeCookies();
    //     localStorage.removeItem('loggedin');
    //     // setShowSignLoginModal(true);
    //   }
    // }, 2000);

    // Abhi
    if (!getCookie('accessToken') && !localStorage.getItem('loggedin')) {
      removeSession();
      removeCookies();
      localStorage.removeItem('loggedin');
      // setShowSignLoginModal(true);
    }
  }, [!isLoggedIn]);

  useEffect(() => {
    if (signUpData.flag) {
      setShowSignUpPasswordModal(false);
      setShowCongratulationsMsg(true);
      toast.success('Signup Successful', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    if (signUpData.error) {
      navigate('/');
      toast.error('Email Already Registered', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    // dispatch(postSignupResetAction());
  }, [signUpData]);

  const handleLogin = async (emailInput, passwordInput) => {
    try {
      const body = {
        email: emailInput,
        password: passwordInput,
      };
      const response = await axios.post(
        `${process.env.apiUrl}v0/appLogin`,
        body,
      );


      if (response.status === 200 && response.data.access_token) {
        const { access_token } = response.data;
        const bearer = `Bearer ${access_token}`;
        setCookie(bearer, 'accessToken');
        setLoggedIn(true);
        localStorage.setItem('loggedin', true);
        dispatch(getRecordingListAction({}));

        // setTimeout(() => {
        //   navigate('/loggedin');
        // }, 10000);
      } else {
        console.log('Login Unsuccessful response', response);
      }
    } catch (err) {
      console.log('Login error', err);
      // localStorage.clear();
      // removeSession();
      // removeCookies();
      // webAuth.logout({
      //   returnTo: process.env.appUrl,
      //   clientID: process.env.auth0.clientId,
      // });
    }
  };

  const handleEmail = (e) => {
    e.preventDefault();
    if (e.target[0].value && e?.target[1].value) {
      handleLogin(e.target[0].value, e?.target[1].value);
    }
  };

  return (
    <div className="login-page-proper">
      <Row className="row">
        <Col span={10} className="section-1 divider">
          <div className="logos">
            <img className="lullabai-logo" src="/images/logo-ai.png" />
            <img className="alembic-icon" src="/images/alembic-icon.svg" />
          </div>
        </Col>
        <Col span={10} className="section-2">
          <div className="heading-text">Login here</div>

          {!showSignUpQuestionModal && (
            <form
              className="login-table-form-wrap"
              onSubmit={handleEmail}
              autoComplete="off"
            >
              <div className="login-table">
                <div className="password-sec-combine">
                  <div className="note">Email ID</div>
                  <input type="email" required autoFocus />
                  <div className="note">Password</div>
                  <div className="password-input-container">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      required
                      style={{ paddingRight: '45px' }}
                    />
                    <button
                      type="button"
                      className="toggle-password"
                      onClick={togglePasswordVisibility}
                      aria-label={
                        showPassword ? 'Hide password' : 'Show password'
                      }
                      aria-pressed={showPassword}
                    >
                      {showPassword ? (
                        <EyeFilled />
                      ) : (
                        <EyeInvisibleFilled />
                      )}
                    </button>
                  </div>
                </div>
                <div className="login-buttons">
                  <button className="next" type="submit">
                    Login
                  </button>
                  <button className="back" type="button">
                    Back
                  </button>
                </div>
              </div>
            </form>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default SignIn;
