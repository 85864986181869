/* eslint-disable no-constant-condition */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CaretRightFilled, HeartFilled, HeartOutlined, MenuOutlined, PauseCircleFilled, PlayCircleFilled, ShareAltOutlined, StepBackwardOutlined, StepForwardOutlined, VerticalLeftOutlined, VerticalRightOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import Rcookie from 'react-cookies';
import AudioPlayer from 'react-h5-audio-player';
import { toast } from 'react-toastify';
import songs from './demoAudio';
import { getProcessedSongListAction } from './logic';
import Hamburger from '../Menu';
import { getCookie } from '../../common/utils';

function SongList() {
  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const urlSearchParams = new URLSearchParams(location.search);
  const playableSongData = useSelector((state) => state.playableSongData);
  const [audioBlob, setAudioBlob] = useState(null); // State to store audio blob
  const [type, setType] = useState();
  const [playingSongId, setPlayingSongId] = useState();

  const [selectedSong, setSelectedSong] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audioBlobPlayer, setAudioBlobPlayer] = useState(null);
  const [audioElement, setAudioElement] = useState(null);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [currentSong, setCurrentSong] = useState();
  const [isFavouriteSong, setIsFavouriteSong] = useState();

  const [showSong, setShowSong] = useState({ title: '', song: '' }); // Initialize showSong state
  const { data: getProcessedSongListData } = useSelector((state) => state.getProcessedSongListData);
  useEffect(() => {
    if (!getCookie('accessToken')) {
      navigate('/', { replace: true });
    }

    dispatch(getProcessedSongListAction({}));
    // dispatch(getVoiceRecordingAction({
    //   params: {
    //     recording_id: '51a916d7-83a6-4747-906f-36aa95e6d6de',
    //   },
    // }));
  }, []); // Include dispatch in dependency array
  useEffect(() => {
    const fetchAudioBlob = async () => {
      if (selectedSong) {
        try {
          const response = await fetch(selectedSong.audioSrc);
          const blob = await response.blob();
          setAudioBlob(blob);
        } catch (error) {
          console.error('Error fetching audio blob:', error);
        }
      }
    };

    fetchAudioBlob();

    return () => {
      if (audioBlob) {
        URL.revokeObjectURL(audioBlob);
      }
    };
  }, [selectedSong]);

  useEffect(() => {
    if (audioElement && selectedSong && audioBlob) {
      audioElement.pause();
      audioElement.load();
      audioElement.play();
    }
  }, [selectedSong, audioBlob, audioElement]);

  const handleSongClick = (song) => {
    setSelectedSong(song);
  };

  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.audio.current.play();
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    if (audioRef.current) {
      audioRef.current.audio.current.pause();
      setIsPlaying(false);
    }
  };
  const playAudio = (songData, showType, idx) => {
    handlePause();
    setCurrentSong(songData);
    setIsFavouriteSong(songData.is_favourite);
    setShowAudioPlayer(true);
    setTimeout(() => {
      if (idx > -1 && idx < getProcessedSongListData.length) {
        setPlayingSongId(idx);
      }
    }, [2000]);
    // setPlayingSongId(idx);
    const endPoint = showType === 'original' ? 'download_predefined_song' : 'download_inferenced_song';
    axios.get(`${process.env.apiUrl}v0/${endPoint}?song_id=${songData?.song_id}`, {
      headers: {
        Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`),
      },
      responseType: 'arraybuffer',
    })
      .then((response) => {
        const blob = new Blob([response.data]);

        //   setTimeout(() => {
        //   }, 3000);
        setAudioBlobPlayer(URL.createObjectURL(blob));
      })
      .catch((err) => {
        // dispatch(downloadDocumentFailure(err));
      });
  };
  // const playPreviousSong = (data) => {
  //   playAudio(getProcessedSongListData[playingSongId - 1], type);
  //   console.log(getProcessedSongListData[playingSongId - 1]);
  // };
  // const playNextSong = (data) => {
  //   playAudio(getProcessedSongListData[playingSongId + 1], type);
  //   console.log(getProcessedSongListData[playingSongId + 1]);
  // };

  const playPreviousSong = () => {
    handlePause();
    const prevSongIndex = playingSongId - 1;
    if (prevSongIndex >= 0 && prevSongIndex < getProcessedSongListData.length) {
      setPlayingSongId(prevSongIndex);
      playAudio(getProcessedSongListData[prevSongIndex], type, prevSongIndex);
    }
  };

  const playNextSong = () => {
    handlePause();
    const nextSongIndex = playingSongId + 1;
    if (nextSongIndex >= 0 && nextSongIndex < getProcessedSongListData.length) {
      setPlayingSongId(nextSongIndex);
      playAudio(getProcessedSongListData[nextSongIndex], type, nextSongIndex);
    }
  };

  // useEffect(() => {
  //   const song_id = urlSearchParams.get('song_id');
  //   type = urlSearchParams.get('type');
  //   const endPoint = type === 'original' ? 'download_predefined_song' : 'download_inferenced_song';
  //   const params = ({
  //     song_id,
  //   });
  //   axios.get(`${process.env.apiUrl}v0/${endPoint}?song_id=${song_id})}`, {
  //     headers: {
  //       // Authorization: getCookie('accessToken'),
  //       Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`),
  //     },
  //     responseType: 'arraybuffer',
  //   })
  //     .then((response) => {
  //       const blob = new Blob([response.data]);
  //       console.log(blob);
  //       console.log(URL.createObjectURL(blob));
  //       setAudioBlob(URL.createObjectURL(blob));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       // dispatch(downloadDocumentFailure(err));
  //     });
  // }, []);

  const AddRemoveFavouriteSong = () => {
    axios.post(`${process.env.apiUrl}v0/save_favourite_song?song_id=${currentSong?.song_id}&is_favourite=${!isFavouriteSong}`, currentSong?.song_id, {
      headers: {
        Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`),
      },
    })
      .then((response) => {
        setIsFavouriteSong(!isFavouriteSong);
        toast.success(response?.data, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .catch((err) => {
        toast.error(err.data, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };
  return (
    getProcessedSongListData && (
      <>
        <Modal className="modal-hover" open={isModalOpen} onCancel={() => setIsModalOpen(false)} style={{ top: '200px' }}>
          <div>
            <label className="song-name">{showSong.title}</label>
          </div>
          <div className="controls">
            <img src="/images/share_icon.svg" />
            <img src="/images/left_arrow.svg" />
            <PlayCircleFilled onClick={() => navigate(`/play-song?song_id=${showSong.audioSrc}&title=${showSong.title}`)} className="play-btn" />
            <img src="/images/right_arrow.svg" />
            {/* <span onClick={AddRemoveFavouriteSong}>
              {isFavouriteSong
                ? <HeartFilled style={{ fontSize: '48px', color: '#FF6000' }} />
                : <HeartOutlined style={{ fontSize: '48px', color: '#FF6000' }} />}
            </span> */}
          </div>
        </Modal>
        <div className="song-list-page">
          <Row className="row">
            <Col span={12} className="section-1 divider">
              <div className="logos">
                <img className="lullabai-logo" src="/images/logo-ai.png" alt="Lullabai Logo" />
                <img className="alembic-icon" src="/images/alembic-icon.svg" alt="Alembic Icon" />
              </div>

            </Col>
            <Hamburger isOpen={false} className="hamberger-icon" />

            {!showAudioPlayer ? (
              <Col span={12} className="section-2">
                <div className="section-2-song-list">
                  <div className="heading">My Playlist</div>
                  <div className="list">
                    {getProcessedSongListData?.length >= 0 && getProcessedSongListData?.map((song, idx) => (
                      <div className="songs" key={idx}>
                        <div className="audio" /* onMouseOver={() => { setIsModalOpen(true); setShowSong({ title: song.file_name, audioSrc: song.song_id }); }} */>
                          {song?.title || `song ${idx + 1}`}
                          <br />
                          {/* <div className="artist-name">{song?.artist}</div>  */}
                        </div>
                        <div className="playing-icons">
                          {' '}
                          {song?.inference_status === 'COMPLETED' ? (
                            <CaretRightFilled
                              className="play-button"
                              onClick={() => { setType('own'); playAudio(song, 'own', idx); }}
                              // onClick={() => { navigate('/playing-song?song_id=${}'); }}
                              alt="Play Button"
                            />
                          ) : (
                            <img
                              className="sandclock-logo"
                              src="/images/sand-clock.png"
                              alt=""
                              // onClick={() => { setType('own'); playAudio(song, 'own', idx); }}
                            />
                          )}
                        </div>

                      </div>
                    ))}
                  </div>
                  {/* {audioBlob && (
                  <audio controls ref={(element) => setAudioElement(element)}>
                    <source src={URL.createObjectURL(audioBlob)} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                )} */}
                </div>
              </Col>
            ) : (
              <Col span={12} className="section-2">
                {type ? (
                  <>
                    <div className="heading">
                      Now playing LullabAI in your voice
                    </div>
                    <div className={`section-2-play-song-${type}`}>
                      <div className="close-icon-div">
                        <div className="close-icon" onClick={() => { setShowAudioPlayer(false); }}>x</div>
                      </div>
                      <div className="title">
                        {/* {urlSearchParams.get('title')} */}
                        {' '}
                        {/* Song Title */}
                        {currentSong?.title}

                      </div>
                      <div className="controls">
                        {/* <ShareAltOutlined className="play-icons" style={{ visibility: 'hidden' }} /> */}
                        <VerticalRightOutlined className="play-icons" onClick={() => playPreviousSong()} />
                        {/* <StepBackwardOutlined className="play-icons" /> */}
                        {!isPlaying ? <PlayCircleFilled onClick={() => handlePlay()} className="play-btn" />
                          : <PauseCircleFilled onClick={() => handlePause()} className="play-btn" />}
                        {/* <StepForwardOutlined className="play-icons" /> */}
                        <VerticalLeftOutlined className="play-icons" onClick={() => playNextSong()} />
                        {/* <span onClick={AddRemoveFavouriteSong}>
                          {isFavouriteSong
                            ? <HeartFilled className="play-icons" />
                            : <HeartOutlined className="play-icons" />}
                        </span> */}
                      </div>
                      <div className="audio-player-wrapper">
                        <AudioPlayer
                          ref={audioRef}
                          autoPlay={false}
                          src={audioBlobPlayer}
                          onPlay={handlePlay}
                          onPause={handlePause}
                        />
                      </div>

                    </div>
                  </>
                ) : (
                  <div className="section-2-play-song-wishlist">
                    <div className="sound-icon disabled">
                      {isPlaying && (
                        <div className="sound-wave">
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                          <i className="bar" />
                        </div>
                      )}
                    </div>
                    <div className="title">
                      <div>
                        {/* {urlSearchParams.get('title')} */}
                        {' '}
                        {/* Song Title */}
                        {currentSong?.title}
                      </div>
                      {' '}
                      <div className="options">
                        <img className="lullabai-logo" src="/images/repeat.svg" alt="Lullabai Logo" />
                        {/* <span onClick={AddRemoveFavouriteSong}>
                          {isFavouriteSong
                            ? <HeartFilled style={{ color: '#FF6000' }} className="play-icons" />
                            : <HeartOutlined style={{ color: '#FF6000' }} className="play-icons" />}
                        </span> */}
                      </div>
                    </div>

                    <div className="audio-player-wrapper">
                      <AudioPlayer
                        ref={audioRef}
                        autoPlay={false}
                        src={audioBlobPlayer}
                        onPlay={handlePlay}
                        onPause={handlePause}
                      />
                    </div>
                    <div className="controls">
                      <ShareAltOutlined style={{ color: '#FF6000', visibility: 'hidden' }} className="play-icons" />
                      <StepBackwardOutlined className="play-icons" />
                      {!isPlaying ? <PlayCircleFilled onClick={() => handlePlay()} className="play-btn" />
                        : <PauseCircleFilled onClick={() => handlePause()} className="play-btn" />}
                      <StepForwardOutlined className="play-icons" />
                      <MenuOutlined className="play-icons-menu" />
                      {/* <HeartFilled style={{ color: '#FF6000' }} className="play-icons" /> */}
                    </div>
                  </div>
                )}
              </Col>
            )}
          </Row>
        </div>
      </>
    )
  );
}

export default SongList;
